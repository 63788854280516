import { ReactNode } from 'react';
import styled from 'styled-components';

export interface SelectGroupProps {
  children: ReactNode[];
  ratio: string[];
  firstChildRightBorder?: boolean;
  className?: string;
}

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Item = styled.div<{ ratio: string; firstChildRightBorder: boolean }>`
  flex: ${({ ratio }) => `${ratio}`};

  &:first-child {
    > *,
    > * > *,
    > * > * > * {
      ${({ firstChildRightBorder }) =>
        !firstChildRightBorder && 'border-right-color: transparent;'};
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  & + & {
    > div > button,
    > *,
    > * > *,
    > * > * > * {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  &:not(:last-child) {
    > div > button,
    > *,
    > * > *,
    > * > * > * {
      border-top-right-radius: 0 !important;
    }
  }
`;

function SelectGroup({
  children,
  ratio,
  firstChildRightBorder = false,
  className,
}: SelectGroupProps) {
  return (
    <Container className={className}>
      {children.map((child, index) => (
        <Item
          key={index}
          ratio={ratio[index]}
          firstChildRightBorder={firstChildRightBorder}
        >
          {child}
        </Item>
      ))}
    </Container>
  );
}

export { SelectGroup };
